import React from 'react';

class Ablauf extends React.Component {
  state = {
    opened: false,
  };

  render() {
    const {
      state: { opened },
    } = this;

    return (
      <div className="mb-4">
        <a
          className={
            (opened ? 'hidden' : '') + ' text-blue-500 block md:hidden pt-3'
          }
          onClick={() => {
            this.setState({ opened: !opened });
          }}
        >
          <i className="las la-chevron-circle-down"></i> So sieht der
          Bestellablauf aus.
        </a>
        <div className={(opened ? '' : 'hidden') + ' md:block'}>
          <h2 className="mt-6">Ablauf</h2>
          <ol className="list-decimal pl-4 pb-4">
            <li className="pb-2">
              Nach dem Absenden des Bestellformulars erhalten Sie von uns eine
              Offerte.
            </li>
            <li className="pb-2">
              Sie unterzeichnen die Offerte und senden sie uns als PDF an{' '}
              <a
                href="mailto:hello@sofortshop.ch"
                className="text-blue-500 hover:underline"
              >
                hello@sofortshop.ch
              </a>
              .
            </li>
            <li className="pb-2">
              Wir kontaktieren Sie bei Erhalt der Offerte telefonisch und
              besprechen Ihr Anliegen in einem kurzen Gespräch (bis 30min).
            </li>
            <li className="pb-2">
              Während wir Ihren Shop einrichten, eröffnen Sie ein Konto für
              Kreditkartenzahlungen und bestellen allenfalls Ihren Domainnamen.
            </li>
            <li className="pb-2">
              Nach zwei Tagen nehmen wir Ihren Shop in Betrieb.
            </li>
          </ol>

          <p>Wir versorgen Sie mit einer Anleitung zum genauen Vorgehen.</p>
          <a
            className={
              (opened ? '' : 'hidden') + ' text-blue-500 block md:hidden pt-3'
            }
            onClick={() => {
              this.setState({ opened: !opened });
            }}
          >
            Ablauf verbergen
          </a>
        </div>
      </div>
    );
  }
}

export default Ablauf;
