import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';

function ContactForm() {
  const [hasBeenSubmitted, toggleHasBeenSubmitted] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query OrderFormQuery {
          site {
            siteMetadata {
              order_post_url
            }
          }
        }
      `}
      render={data => (
        <div>
          <Formik
            initialValues={{
              package: 'basic',
              company: '',
              firstname: '',
              name: '',
              address: '',
              zip: '',
              location: '',
              email: '',
              phone: '',
              message: '',
              terms: ''
            }}
            validate={values => {
              let errors = {};

              if (values.company) {
                if (values.company.length > 128) {
                  errors.company =
                    'Es dürfen höchstens 128 Zeichen eingegeben werden';
                }
              }

              if (!values.firstname) {
                errors.firstname = 'Eingabe wird benötigt';
              } else if (values.firstname.length < 2) {
                errors.firstname =
                  'Es müssen mindestens 2 Zeichen eingegeben werden';
              } else if (values.firstname.length > 64) {
                errors.firstname =
                  'Es dürfen höchstens 64 Zeichen eingegeben werden';
              }

              if (!values.name) {
                errors.name = 'Eingabe wird benötigt';
              } else if (values.name.length < 2) {
                errors.name =
                  'Es müssen mindestens 2 Zeichen eingegeben werden';
              } else if (values.name.length > 64) {
                errors.name =
                  'Es dürfen höchstens 64 Zeichen eingegeben werden';
              }

              if (!values.address) {
                errors.address = 'Eingabe wird benötigt';
              } else if (values.address.length < 2) {
                errors.address =
                  'Es müssen mindestens 2 Zeichen eingegeben werden';
              } else if (values.address.length > 64) {
                errors.address =
                  'Es dürfen höchstens 64 Zeichen eingegeben werden';
              }

              if (!values.zip) {
                errors.zip = 'Eingabe wird benötigt';
              } else if (values.zip.length < 4) {
                errors.zip = 'Es müssen mindestens 4 Zeichen eingegeben werden';
              } else if (values.zip.length > 8) {
                errors.zip = 'Es dürfen höchstens 8 Zeichen eingegeben werden';
              }

              if (!values.location) {
                errors.location = 'Eingabe wird benötigt';
              } else if (values.location.length < 2) {
                errors.location =
                  'Es müssen mindestens 2 Zeichen eingegeben werden';
              } else if (values.location.length > 64) {
                errors.location =
                  'Es dürfen höchstens 64 Zeichen eingegeben werden';
              }

              if (!values.email) {
                errors.email = 'Eingabe wird benötigt';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Ungültige E-Mail Adresse';
              } else if (values.email.length > 255) {
                errors.location =
                  'Es dürfen höchstens 255 Zeichen eingegeben werden';
              }

              if (!values.phone) {
                errors.phone = 'Eingabe wird benötigt';
              } else if (values.phone.length < 9) {
                errors.phone =
                  'Es müssen mindestens 9 Zeichen eingegeben werden';
              } else if (values.phone.length > 32) {
                errors.phone =
                  'Es dürfen höchstens 32 Zeichen eingegeben werden';
              }

              if (values.message) {
                if (values.message.length > 4096) {
                  errors.message =
                    'Es dürfen höchstens 4096 Zeichen eingegeben werden';
                }
              }

              if (values.terms !== true) {
                errors.terms = 'Bitte akzeptieren Sie unsere AGB';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                axios
                  .post(data.site.siteMetadata.order_post_url, values)
                  .then(function(response) {
                    // Track Matomo Goal, if Matomo loaded (ie without do not track)
                    if (window._paq) {
                      window._paq.push(['trackGoal', 1]);
                    }
                    toggleHasBeenSubmitted(!hasBeenSubmitted);
                  })
                  .catch(function(error) {
                    alert(
                      'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut, oder kontaktieren Sie uns per E-Mail.'
                    );
                  });
                setSubmitting(false);
              }, 400);
            }}
          >
            {function({ isSubmitting, errors, touched }) {
              if (hasBeenSubmitted) {
                return (
                  <p>
                    Herzlichen Dank für Ihre Bestellung. Wir melden uns bald bei
                    Ihnen.
                  </p>
                );
              } else {
                return (
                  <Form className="w-full">
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-package"
                        >
                          Shop-Paket
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <div className="relative">
                          <Field
                            className={`
                            shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white
                            ${
                              errors.package && touched.package
                                ? 'border-red-500'
                                : ''
                            }`}
                            as="select"
                            name="package"
                            id="form-package"
                          >
                            <option value="basic">"All-In-One"-Shopsystem</option>
                            <option value="multilang">"All-In-One" Mehrsprachig</option>
                            <option value="monthly">"All-In-One" Teilzahlung</option>
                          </Field>
                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="package"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-company"
                        >
                          Firma (optional)
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.company && touched.company
                              ? 'border-red-500'
                              : ''
                          }`}
                          type="text"
                          name="company"
                          id="form-company"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="company"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-firstname"
                        >
                          Vorname
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.firstname && touched.firstname
                              ? 'border-red-500'
                              : ''
                          }`}
                          type="text"
                          name="firstname"
                          id="form-firstname"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="firstname"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-name"
                        >
                          Name
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.name && touched.name ? 'border-red-500' : ''
                          }`}
                          type="text"
                          name="name"
                          id="form-name"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="name"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-address"
                        >
                          Adresse
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.address && touched.address
                              ? 'border-red-500'
                              : ''
                          }`}
                          type="text"
                          name="address"
                          id="form-address"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="address"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-zip"
                        >
                          PLZ
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.zip && touched.zip ? 'border-red-500' : ''
                          }`}
                          type="text"
                          name="zip"
                          id="form-zip"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="zip"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-location"
                        >
                          Ort
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.location && touched.location
                              ? 'border-red-500'
                              : ''
                          }`}
                          type="text"
                          name="location"
                          id="form-location"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="location"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-email"
                        >
                          E-Mail
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.email && touched.email
                              ? 'border-red-500'
                              : ''
                          }`}
                          type="email"
                          name="email"
                          id="form-email"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="email"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-phone"
                        >
                          Telefonnummer
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.phone && touched.phone
                              ? 'border-red-500'
                              : ''
                          }`}
                          type="text"
                          name="phone"
                          id="form-phone"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="phone"
                          component="div"
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-message"
                        >
                          Bemerkung (optional)
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field
                          className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                            errors.message && touched.message
                              ? 'border-red-500'
                              : ''
                          }`}
                          as="textarea"
                          name="message"
                          id="form-message"
                        />
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="message"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="md:flex md:items-top mb-6">
                      <div className="md:w-1/3">
                        <label
                          className="block text-gray-800 font-bold md:text-right mb-1 md:mb-0 pr-4 py-2"
                          htmlFor="form-terms"
                        >
                          Ich habe die{' '}
                          <a
                            href="/agb"
                            className="text-blue-500 hover:underline"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            AGB
                          </a>{' '}
                          gelesen und akzeptiere diese
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <Field name="terms">
                          {({ field, form, meta }) => (
                            <input
                              type="checkbox"
                              id="form-terms"
                              className="leading-tight mt-3"
                              checked={field.value}
                              {...field}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          className="pt-1 text-red-500 text-sm italic"
                          name="terms"
                          component="div"
                        />
                      </div>
                    </div>

                    <div className="md:flex md:items-center">
                      <div className="md:w-1/3"></div>
                      <div className="md:w-2/3">
                        <button
                          className="hover:underline gradient text-white font-bold rounded-full py-2 px-8 shadow-lg"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Senden
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }
            }}
          </Formik>
        </div>
      )}
    />
  );
}

export default ContactForm;
