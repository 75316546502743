import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FooterWaves from '../components/footer-waves';
import OrderForm from '../components/order-form';
import Ablauf from '../components/ablauf';
import HeaderWaves from '../components/header-waves';

function Kontakt() {
  return (
    <Layout>
      <SEO
        title="Einen Sofortshop bestellen"
        description="Handeln Sie jetzt und bringen Sie Ihr Geschäft in 2 Tagen online. Wir unterstützen Sie dabei."
      />
      <HeaderWaves />
      <section className="bg-white py-4 md:py-8">
        <div className="container max-w-5xl mx-auto text-gray-800">
          <h1 className="text-center text-gray-800 text-3xl md:text-4xl">
            Bestellformular
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="container max-w-5xl mx-auto mt-2 mb-8 mt-12">
            <div className="flex flex-wrap">
              <div className="w-5/6 sm:w-1/3 px-6">
                <p>
                  Wir freuen uns, dass Sie von unserer Shoplösung profitieren
                  möchten. Bitte füllen Sie das folgende Bestellformular aus.
                  Wir werden Sie schnellstmöglich kontaktieren.
                </p>
                <Ablauf />
              </div>
              <div className="w-full sm:w-2/3 px-6">
                <OrderForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterWaves />
    </Layout>
  );
}

export default Kontakt;
